<template>
  <div class="pasenger_form">
    <b-form ref="form" class="d-flex justify-content-between" @submit.stop.prevent="() => {}">
      <h4>{{ $t("product-page.customer-details")  + (device == "desktop" ? " : " : "") }}</h4>
      <b-form-group :label='device==="desktop" ? $t("booking.first-name") : ""' label-for="input-2">
        <b-form-input
          v-model="firstName"
          :placeholder="$t('booking.first-name')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="firstNameState"
          aria-describedby="input-firstname-feedback"
        ></b-form-input>
        <!-- <b-form-invalid-feedback id="input-firstname-feedback" v-if="device==='desktop'">
          <div v-if="firstName.length < 3"> {{ $t("booking.no-empty") }} </div>
          <div v-else> {{ $t("booking.only-eng-character-message") }} </div>
        </b-form-invalid-feedback> -->
        <b-form-invalid-feedback id="input-firstname-feedback">
          <div> {{ $t("booking.required-field") }} </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.last-name") : ""' label-for="input-2">
        <b-form-input
          v-model="lastName"
          :placeholder="$t('booking.last-name')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="lastNameState"
          aria-describedby="input-lastname-feedback"
        ></b-form-input>
        <!-- <b-form-invalid-feedback id="input-lastname-feedback" v-if="device==='desktop'">
          <div v-if="lastName.length < 3"> {{ $t("booking.no-empty") }} </div>
          <div v-else> {{ $t("booking.only-eng-character-message") }} </div>
        </b-form-invalid-feedback> -->
        <b-form-invalid-feedback id="input-lastname-feedback">
          <div> {{ $t("booking.required-field") }} </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.phone") : ""' label-for="input-2">
        <b-form-input
          v-model="phone"
          :placeholder="$t('booking.phone')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="phoneState"
          aria-describedby="input-phone-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-phone-feedback">
          <div> {{ $t("booking.required-field") }} </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.email") : ""' label-for="input-1">
        <b-form-input
          v-model="email"
          type="email"
          :placeholder="$t('booking.email')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="emailState"
        ></b-form-input>
        <!-- <b-form-invalid-feedback id="input-email-feedback">
          {{ supplier.toLowerCase() === 'ISRO'.toLowerCase() ? $t("login.validate-email-message") : '' }}
        </b-form-invalid-feedback> -->
        <b-form-invalid-feedback id="input-email-feedback">
          <div> {{ $t("booking.required-field") }} </div>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary" class="d-none">{{ $t("booking.submit") }}</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback } from 'bootstrap-vue';
import dayjs from 'dayjs';

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paxTypes: [
        { value: 'M', text: this.$t('booking.mr') },
        { value: 'F', text: this.$t('booking.miss') },
        { value: 'C', text: this.$t('booking.children') },
        { value: 'I', text: this.$t('booking.infant') },
      ],
      firstName: '',
      lastName: '',
      paxType: 'M',
      email: '',
      phone: '',
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        viewMode: 'years',
      },
      birthDate: dayjs().subtract(25, 'year').toString(),
    };
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
      isOdysseyAgent: 'GET_ODYSSEY_AGENT_STATE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    fNameState() {
      return !!this.firstName;
    },
    lNameState() {
      return !!this.lastName;
    },
    emailState() {
      return !!this.email && !!this.validateEmail(this.email);
    },
    phoneState() {
      return !!this.phone;
    },
    firstNameState() {
      return !(this.supplier.toLowerCase() === 'ISRO'.toLowerCase() && /[^A-Za-z]/ig.test(this.firstName)) && this.firstName.trim().length > 2;
    },
    lastNameState() {
      return !(this.supplier.toLowerCase() === 'ISRO'.toLowerCase() && /[^A-Za-z]/ig.test(this.lastName)) && this.lastName.trim().length > 2;
    },
  },
  watch: {
    // readOnly() {
    //   if (this.readOnly) {
    //     this.$refs.datepicker.dp.disable();
    //   } else {
    //     this.$refs.datepicker.dp.enable();
    //   }
    // },
    // lang: 'updateLableWithLang',
    user: {
      handler() {
        this.setUserInformation();
        this.updateUserInfo();
      },
      deep: true,
    },
  },
  methods: {
    updateUserInfo() {
      if (this.supplier.toLowerCase() === 'ISRO'.toLowerCase()) {
        this.firstName = this.firstName.replace(/[^A-Za-z]/ig, '');
        this.lastName = this.lastName.replace(/[^A-Za-z]/ig, '');
      }
      this.firstName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
      this.lastName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);
      const userInfo = {
        validate: false,
        groupID: this.user.groupID,
        id: this.user.id,
        firstName: this.firstName,
        lastName: this.lastName,
        paxType: this.paxType,
        email: this.email,
        phone: this.phone,
        birthDate: this.birthDate,
      };
      userInfo.validate = (this.fNameState && this.lNameState && (this.isOdysseyAgent || (this.user.generation === 'adult' && this.emailState && this.phoneState) || this.user.generation !== 'adult'));

      this.$emit('updateUser', userInfo);
    },
    setUserInformation() {
      this.email = this.user.email;
      this.phone = this.user.phone;
    },
    limitPaxtypes() {
      switch (this.user.generation) {
        case 'adult':
          this.paxTypes[2].disabled = true;
          this.paxTypes[3].disabled = true;
          this.paxType = 'M';
          break;
        case 'child':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'C';
          break;
        case 'infant':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'I';
          break;
        default:
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
  created() {
    this.limitPaxtypes();
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email || this.user.emailAddress;
    this.phone = this.user.phone || this.user.mobileNumber;
    if (this.devMode) {
      if (this.firstName === '') this.firstName = 'test';
      if (this.lastName === '') this.lastName = 'test';
      if (this.email === '') this.email = 'ofirfishler@gmail.com';
      if (this.phone === '') this.phone = '543320148';
    }
    this.updateUserInfo();
  },
  mounted() {
  },
};
</script>

<style>
  .was-validated .form-control:valid, .form-control.is-valid, .form-control.is-invalid {
    padding-right: 0.75rem !important;
  }
  .invalid-feedback{
    line-height: 0.9rem;
    border-radius: 2px;
    color: #fff;
  }
  .right-align .form-control.is-valid,
  .right-align .form-control.is-invalid{
      background-position: left calc(0.375em + 0.1875rem) center;
  }
  .form-group label {
    font-size: 15px;
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .form-group input{
    font-size : 15px !important;
  }
  @media (max-width: 479px){
    .pasenger_form fieldset.form-group{
      display: flex;
      margin: 0px;
      width: 50%;
      float: left;
    }
    .invalid-feedback{
      color: #a10202;
    }
  }

</style>

<style scoped>
.left-align .form-group,
.left-align h4 {
  text-align: left;
}
.pasenger_form {
  box-sizing: border-box;
  margin-bottom: 15px;
  z-index: 91;
}

.passenger_body .pasenger_form h4 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0;
  padding: 0;
  text-transform: capitalize;
  letter-spacing: 1px;
  width: 100%;
  max-width: 54px;
  line-height: 26px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .passenger_body .pasenger_form h4 {
    max-width: 132px;
  }
}

.pasenger_form .form-group {
  position: relative;
  height: 76px;
  min-width: 100px;
  margin-right: 30px;
  width: 100%;
}
/*
.pasenger_form fieldset.form-group{
  display: -webkit-inline-box;
} */
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pasenger_form .form-group .form-icon {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 15px;
  font-size: 20px;
  color: #606060;
}

.pasenger_form fieldset {
  direction: ltr;
  margin-top : -3px;
}

@media (max-width: 479px) {
  .pasenger_form {
    padding: 5px 5px;
    border: 1px solid #e5e5e5;
  }

  .passenger_body .pasenger_form h4 {
    color: rgb(33, 37, 41);
    line-height: 1.2;
  }

  .pasenger_form .form-group {
    color: rgb(33, 37, 41) !important;
  }

  .pasenger_form .form-group label {
    margin-top: 12px;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .pasenger_form form {
    display: inline-block !important;
  }
  .pasenger_form .form-group {
    width: 50%;
    display: inline-block;
    margin: auto;
    padding: 3px 10px;
    height: fit-content;
    vertical-align: top;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .passenger_box_two .pasenger_form form {
    display: inline-block !important;
  }

  .passenger_box_two .pasenger_form .form-group {
    width: 33%;
    display: inline-block !important;
    margin: auto;
    padding: 10px;
  }
}
/*
    @media (min-width: 1200px) {
        .form-group label, .form-group label.d-block{
            font-size: 15px;
        }
    } */
</style>
